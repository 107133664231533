import type {File, Image} from '~/types/sofie'
import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from '@vue/composition-api';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  Email: { input: string; output: string; }
  File: { input: File; output: File; }
  Iban: { input: string; output: string; }
  Image: { input: Image; output: Image; }
  JSON: { input: any; output: any; }
  Mixed: { input: any; output: any; }
  Null: { input: null; output: null; }
  Upload: { input: any; output: any; }
  Uuid: { input: string; output: string; }
};

export type Address = {
  __typename?: 'Address';
  capabilities: Array<Capability>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  geodata?: Maybe<Scalars['JSON']['output']>;
  house_number?: Maybe<Scalars['String']['output']>;
  house_number_suffix?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  street?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  zipcode?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  house_number: Scalars['String']['input'];
  house_number_suffix?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  zipcode: Scalars['String']['input'];
};

export type Application = {
  __typename?: 'Application';
  capabilities: Array<Capability>;
  client: Scalars['String']['output'];
  cover_letter: Array<Scalars['File']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['Email']['output'];
  error?: Maybe<Scalars['String']['output']>;
  first_name: Scalars['String']['output'];
  form_type: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  last_name: Scalars['String']['output'];
  mailings?: Maybe<Scalars['Boolean']['output']>;
  phone_number: Scalars['String']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  resume: Array<Scalars['File']['output']>;
  synced_at?: Maybe<Scalars['DateTime']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  utm_campaign?: Maybe<Scalars['String']['output']>;
  utm_content?: Maybe<Scalars['String']['output']>;
  utm_medium?: Maybe<Scalars['String']['output']>;
  utm_source?: Maybe<Scalars['String']['output']>;
  utm_term?: Maybe<Scalars['String']['output']>;
  vacancies?: Maybe<Vacancy>;
};

export type Arbochecklist = {
  __typename?: 'Arbochecklist';
  attachment: Array<Scalars['File']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type ArbochecklistsRegistration = {
  __typename?: 'ArbochecklistsRegistration';
  arbochecklist?: Maybe<Arbochecklist>;
  attachment: Array<Scalars['File']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

/** list of values that branches can be sorted by */
export type BranchSortables = {
  /** location from which to look for nearest branches */
  city?: InputMaybe<Scalars['String']['input']>;
  coordinates?: InputMaybe<Coordinates>;
};

export type Cao = {
  __typename?: 'Cao';
  active_image?: Maybe<Scalars['Image']['output']>;
  adv?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  expense_reimbursement?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  initial_pay_raise?: Maybe<Scalars['String']['output']>;
  irregular_hours?: Maybe<Scalars['String']['output']>;
  kostenvergoedingen?: Maybe<Scalars['String']['output']>;
  overtime?: Maybe<Scalars['String']['output']>;
  payment?: Maybe<Scalars['String']['output']>;
  periodic_salary_increase?: Maybe<Scalars['String']['output']>;
  physical_conditions?: Maybe<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  shift_work?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  travel_time_allowance?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  weekly_working_hours?: Maybe<Scalars['String']['output']>;
  working_hours: Array<Scalars['String']['output']>;
};

export type Capability = {
  __typename?: 'Capability';
  name: Scalars['String']['output'];
};

export type Category = {
  __typename?: 'Category';
  active_image?: Maybe<Scalars['Image']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<Maybe<Vraag>>>;
  text?: Maybe<Scalars['String']['output']>;
};

export type CompanyAddressInformation = {
  __typename?: 'CompanyAddressInformation';
  address?: Maybe<Address>;
  company_name?: Maybe<Scalars['String']['output']>;
  legal_form?: Maybe<Scalars['String']['output']>;
};

/** latitude and longitude */
export type Coordinates = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type Dienstverband = {
  __typename?: 'Dienstverband';
  capabilities: Array<Capability>;
  client: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  wordpress_id: Scalars['Int']['output'];
};

export type Document = {
  __typename?: 'Document';
  bestand: Array<Scalars['File']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type Form = {
  __typename?: 'Form';
  fields: Array<FormField>;
  id: Scalars['Int']['output'];
  schema: Scalars['JSON']['output'];
  schema_v2: Scalars['Mixed']['output'];
  slug: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['Uuid']['output'];
};

export type FormData = {
  __typename?: 'FormData';
  fields?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['Int']['output'];
};

export type FormField = {
  __typename?: 'FormField';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  options?: Maybe<Scalars['String']['output']>;
  position: Scalars['Int']['output'];
  regex?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type: Scalars['String']['output'];
  unique?: Maybe<Scalars['Boolean']['output']>;
};

export type FormFieldAttachment = {
  attachment: Scalars['Upload']['input'];
  field_name: Scalars['String']['input'];
};

export type FormSubmitResponse = {
  __typename?: 'FormSubmitResponse';
  confirm_url?: Maybe<Scalars['String']['output']>;
};

export type Functie = {
  __typename?: 'Functie';
  aantal_vacatures?: Maybe<Scalars['Int']['output']>;
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  client?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  intro?: Maybe<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  sector?: Maybe<Sector>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  wordpress_id?: Maybe<Scalars['Int']['output']>;
};

export type HoursInput = {
  email: Scalars['Email']['input'];
  employer_or_customer?: InputMaybe<Scalars['String']['input']>;
  first_name: Scalars['String']['input'];
  insertion?: InputMaybe<Scalars['String']['input']>;
  last_name: Scalars['String']['input'];
};

export type Hulpmiddel = {
  __typename?: 'Hulpmiddel';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  document: Array<Scalars['File']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Inlener = {
  __typename?: 'Inlener';
  address?: Maybe<Address>;
  adv?: Maybe<Scalars['String']['output']>;
  adv_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  adv_yes_or_no_1?: Maybe<Scalars['Boolean']['output']>;
  branch_number?: Maybe<Scalars['String']['output']>;
  cao?: Maybe<Cao>;
  cao_salary_bool?: Maybe<Scalars['Boolean']['output']>;
  cao_tables: Array<Scalars['File']['output']>;
  cao_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  capabilities: Array<Capability>;
  city?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  company_rules: Array<Scalars['File']['output']>;
  contact_name?: Maybe<Scalars['String']['output']>;
  cost_center?: Maybe<Scalars['String']['output']>;
  cost_center_bool?: Maybe<Scalars['Boolean']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  employer_or_customer?: Maybe<Scalars['String']['output']>;
  expense_reimbursement?: Maybe<Scalars['String']['output']>;
  /** Kostenvergoedingen die vrij van loonheffing worden verstrekt */
  expense_reimbursement_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  first_name?: Maybe<Scalars['String']['output']>;
  function?: Maybe<Scalars['String']['output']>;
  function_levels: Array<Scalars['File']['output']>;
  functions?: Maybe<Scalars['JSON']['output']>;
  g_bill?: Maybe<Scalars['Float']['output']>;
  g_bill_bool?: Maybe<Scalars['Boolean']['output']>;
  iban?: Maybe<Scalars['Boolean']['output']>;
  iban_number?: Maybe<Scalars['Iban']['output']>;
  id: Scalars['Int']['output'];
  initial_pay_raise?: Maybe<Scalars['String']['output']>;
  /** Hoogte en tijdstip van initiële loonsverhoging */
  initial_pay_raise_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  insertion?: Maybe<Scalars['String']['output']>;
  invoice_email?: Maybe<Scalars['Email']['output']>;
  invoice_name?: Maybe<Scalars['String']['output']>;
  irregular_hours?: Maybe<Scalars['String']['output']>;
  /** Toeslag voor onregelmatige werktijden en/of verschoven uren */
  irregular_hours_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  kostenvergoeding?: Maybe<Scalars['JSON']['output']>;
  kvk_number?: Maybe<Scalars['String']['output']>;
  last_name?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  no_cao_expense_reimbursement?: Maybe<Scalars['String']['output']>;
  no_cao_initial_raise?: Maybe<Scalars['String']['output']>;
  no_cao_irregular_hours?: Maybe<Scalars['String']['output']>;
  no_cao_overtime?: Maybe<Scalars['String']['output']>;
  no_cao_payment?: Maybe<Scalars['String']['output']>;
  no_cao_periodic_raise?: Maybe<Scalars['String']['output']>;
  no_cao_physical_conditions?: Maybe<Scalars['String']['output']>;
  no_cao_resting_hours?: Maybe<Scalars['String']['output']>;
  no_cao_salary?: Maybe<Scalars['String']['output']>;
  no_cao_shift_work?: Maybe<Scalars['String']['output']>;
  no_cao_travel_time_allowance?: Maybe<Scalars['String']['output']>;
  no_cao_unworkable_weather?: Maybe<Scalars['String']['output']>;
  no_cao_working_hours?: Maybe<Scalars['String']['output']>;
  overtime?: Maybe<Scalars['String']['output']>;
  /** Toeslag voor overwerk */
  overtime_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  payment?: Maybe<Scalars['String']['output']>;
  /** Hoogte en tijdstip van een eenmalige uitkering en/of een eindejaarsuitkering */
  payment_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  periodic_salary_increase?: Maybe<Scalars['String']['output']>;
  /** Hoogte en tijdstip van de periodieke loonsverhoging */
  periodic_salary_increase_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  person_or_department?: Maybe<Scalars['String']['output']>;
  person_or_department_bool?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  physical_conditions?: Maybe<Scalars['String']['output']>;
  /** Toeslag voor werken onder (fysieke) omstandigheden */
  physical_conditions_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  resting_hours?: Maybe<Scalars['String']['output']>;
  salary?: Maybe<Scalars['String']['output']>;
  sector?: Maybe<Scalars['String']['output']>;
  shift_work?: Maybe<Scalars['String']['output']>;
  /** Toeslag voor werken in ploegen */
  shift_work_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  times?: Maybe<Scalars['JSON']['output']>;
  title: Scalars['String']['output'];
  travel_time_allowance?: Maybe<Scalars['String']['output']>;
  /** Vergoeding voor reisuren of reistijd */
  travel_time_allowance_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  unworkable_weather?: Maybe<Scalars['String']['output']>;
  /** Is er binnen uw onderneming sprake van de regeling ‘onwerkbaar weer’? Zo ja, hoe wordt deze regeling gehanteerd? */
  unworkable_weather_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vat?: Maybe<Scalars['Boolean']['output']>;
  vat_number?: Maybe<Scalars['String']['output']>;
  work_hours?: Maybe<Scalars['String']['output']>;
  /** Wat is de wekelijkse arbeidsduur binnen uw onderneming? */
  work_hours_yes_or_no?: Maybe<Scalars['Boolean']['output']>;
  working_hours?: Maybe<Scalars['String']['output']>;
};

export type InlenerInput = {
  adv?: InputMaybe<Scalars['String']['input']>;
  adv_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  adv_yes_or_no_1?: InputMaybe<Scalars['Boolean']['input']>;
  cao?: InputMaybe<Scalars['ID']['input']>;
  cao_salary_bool?: InputMaybe<Scalars['Boolean']['input']>;
  cao_tables?: InputMaybe<Scalars['Upload']['input']>;
  cao_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  company_rules?: InputMaybe<Scalars['Upload']['input']>;
  expense_reimbursement?: InputMaybe<Scalars['String']['input']>;
  expense_reimbursement_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  functions?: InputMaybe<Scalars['JSON']['input']>;
  initial_pay_raise?: InputMaybe<Scalars['String']['input']>;
  initial_pay_raise_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  irregular_hours?: InputMaybe<Scalars['String']['input']>;
  irregular_hours_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  kostenvergoeding?: InputMaybe<Scalars['JSON']['input']>;
  no_cao_expense_reimbursement?: InputMaybe<Scalars['String']['input']>;
  no_cao_initial_raise?: InputMaybe<Scalars['String']['input']>;
  no_cao_irregular_hours?: InputMaybe<Scalars['String']['input']>;
  no_cao_overtime?: InputMaybe<Scalars['String']['input']>;
  no_cao_payment?: InputMaybe<Scalars['String']['input']>;
  no_cao_periodic_raise?: InputMaybe<Scalars['String']['input']>;
  no_cao_physical_conditions?: InputMaybe<Scalars['String']['input']>;
  no_cao_resting_hours?: InputMaybe<Scalars['String']['input']>;
  no_cao_salary?: InputMaybe<Scalars['String']['input']>;
  no_cao_shift_work?: InputMaybe<Scalars['String']['input']>;
  no_cao_travel_time_allowance?: InputMaybe<Scalars['String']['input']>;
  no_cao_unworkable_weather?: InputMaybe<Scalars['String']['input']>;
  no_cao_working_hours?: InputMaybe<Scalars['String']['input']>;
  overtime?: InputMaybe<Scalars['String']['input']>;
  overtime_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  payment?: InputMaybe<Scalars['String']['input']>;
  payment_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  periodic_salary_increase?: InputMaybe<Scalars['String']['input']>;
  periodic_salary_increase_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  physical_conditions?: InputMaybe<Scalars['String']['input']>;
  physical_conditions_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  resting_hours?: InputMaybe<Scalars['String']['input']>;
  salary?: InputMaybe<Scalars['String']['input']>;
  sector?: InputMaybe<Scalars['String']['input']>;
  shift_work?: InputMaybe<Scalars['String']['input']>;
  shift_work_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  table_pdf?: InputMaybe<Scalars['Upload']['input']>;
  times?: InputMaybe<Scalars['JSON']['input']>;
  travel_time_allowance?: InputMaybe<Scalars['String']['input']>;
  travel_time_allowance_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  unworkable_weather?: InputMaybe<Scalars['String']['input']>;
  unworkable_weather_yes_or_no?: InputMaybe<Scalars['Boolean']['input']>;
  work_hours?: InputMaybe<Scalars['String']['input']>;
  working_hours?: InputMaybe<Scalars['String']['input']>;
};

export type InvoicingInput = {
  cost_center?: InputMaybe<Scalars['String']['input']>;
  cost_center_bool: Scalars['Boolean']['input'];
  g_bill?: InputMaybe<Scalars['Float']['input']>;
  g_bill_bool: Scalars['Boolean']['input'];
  iban: Scalars['Boolean']['input'];
  iban_number?: InputMaybe<Scalars['Iban']['input']>;
  invoice_email: Scalars['Email']['input'];
  invoice_name?: InputMaybe<Scalars['String']['input']>;
  person_or_department?: InputMaybe<Scalars['String']['input']>;
  person_or_department_bool: Scalars['Boolean']['input'];
  vat: Scalars['Boolean']['input'];
  vat_number?: InputMaybe<Scalars['String']['input']>;
};

export type JobAlert = {
  __typename?: 'JobAlert';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type KvkInput = {
  branch_number: Scalars['String']['input'];
  company_name: Scalars['String']['input'];
  contact_name: Scalars['String']['input'];
  kvk_number: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type KvkResult = {
  __typename?: 'KvkResult';
  branch_number?: Maybe<Scalars['String']['output']>;
  company_name?: Maybe<Scalars['String']['output']>;
  kvk_number?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

/** a type that describes the page content, it's metadata and 5 related vacancies */
export type LandingPage = {
  __typename?: 'LandingPage';
  page?: Maybe<PageContent>;
  professions?: Maybe<Array<Maybe<Functie>>>;
  recruiter?: Maybe<Recruiter>;
  sector?: Maybe<Sector>;
  vacancies?: Maybe<Array<Maybe<Vacancy>>>;
};

export enum Locale {
  Nl = 'nl'
}

export type Menu = {
  __typename?: 'Menu';
  items: Array<MenuItem>;
  name?: Maybe<Scalars['String']['output']>;
};

export type MenuItem = {
  __typename?: 'MenuItem';
  capability?: Maybe<Scalars['String']['output']>;
  children: Array<MenuItem>;
  target?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptInvite?: Maybe<Scalars['Boolean']['output']>;
  changePassword?: Maybe<Scalars['Null']['output']>;
  disableTwoFactor?: Maybe<Scalars['Boolean']['output']>;
  enableTwoFactor?: Maybe<Scalars['Boolean']['output']>;
  generateTwoFactorSecret?: Maybe<TwoFactorSecret>;
  login?: Maybe<Scalars['String']['output']>;
  logout?: Maybe<Scalars['Boolean']['output']>;
  requestPasswordReset?: Maybe<Scalars['Boolean']['output']>;
  resetPassword?: Maybe<Scalars['Boolean']['output']>;
  /** signs up for job alerts */
  signUpJobAlerts?: Maybe<Scalars['Boolean']['output']>;
  /** mutates job applications */
  submitApplication?: Maybe<Application>;
  /** mutates arbochecklist registrations */
  submitArbochecklistRegistration?: Maybe<Application>;
  submitForm: FormSubmitResponse;
  submitInlenersForm?: Maybe<Scalars['Null']['output']>;
};


export type MutationAcceptInviteArgs = {
  email: Scalars['Email']['input'];
  invite: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  current_password: Scalars['String']['input'];
  new_password: Scalars['String']['input'];
  new_password_confirm: Scalars['String']['input'];
};


export type MutationEnableTwoFactorArgs = {
  otp: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  two_factor_code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['Email']['input'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['Email']['input'];
  password: Scalars['String']['input'];
  password_confirmation: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSignUpJobAlertsArgs = {
  email: Scalars['Email']['input'];
};


export type MutationSubmitApplicationArgs = {
  email?: InputMaybe<Scalars['Email']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  mailings?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  resume?: InputMaybe<Scalars['Upload']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  utm?: InputMaybe<UtmFields>;
  vacancy_id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSubmitArbochecklistRegistrationArgs = {
  arbochecklist: Scalars['ID']['input'];
  attachment: Scalars['Upload']['input'];
  email: Scalars['Email']['input'];
};


export type MutationSubmitFormArgs = {
  attachments?: InputMaybe<Array<FormFieldAttachment>>;
  extra?: InputMaybe<Scalars['String']['input']>;
  form_data: Scalars['String']['input'];
  form_id: Scalars['ID']['input'];
  g_recaptcha_response?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSubmitInlenersFormArgs = {
  additionalInformation: InlenerInput;
  address: AddressInput;
  hours: HoursInput;
  invoice: InvoicingInput;
  kvk: KvkInput;
  signature: SignatureInput;
};

export type Nieuwsbericht = {
  __typename?: 'Nieuwsbericht';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  employee_name?: Maybe<Scalars['String']['output']>;
  employee_occupation?: Maybe<Scalars['String']['output']>;
  employee_portrait?: Maybe<Scalars['Image']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  summary?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type NieuwsberichtPagination = Pagination & {
  __typename?: 'NieuwsberichtPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data: Array<Nieuwsbericht>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type Opdrachtgever = {
  __typename?: 'Opdrachtgever';
  aantal_vacatures?: Maybe<Scalars['Int']['output']>;
  active_image?: Maybe<Scalars['Image']['output']>;
  alias?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Capability>;
  city?: Maybe<Scalars['String']['output']>;
  color_code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  intro?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  logo?: Maybe<Scalars['Image']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  questions: Array<Vraag>;
  questions_count?: Maybe<Scalars['Int']['output']>;
  recruiter?: Maybe<Recruiter>;
  references: Array<Referentie>;
  references_count?: Maybe<Scalars['Int']['output']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  street?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  usps: Array<Verkoopargument>;
  usps_count?: Maybe<Scalars['Int']['output']>;
  vacancies?: Maybe<Array<Maybe<Vacancy>>>;
  zip?: Maybe<Scalars['String']['output']>;
};


export type OpdrachtgeverLogoArgs = {
  brightness?: InputMaybe<Scalars['Int']['input']>;
  dpr?: InputMaybe<Scalars['Int']['input']>;
  fit?: InputMaybe<Scalars['String']['input']>;
  format?: InputMaybe<Scalars['String']['input']>;
  h?: InputMaybe<Scalars['Int']['input']>;
  quality?: InputMaybe<Scalars['Int']['input']>;
  w?: InputMaybe<Scalars['Int']['input']>;
};

export type Opleiding = {
  __typename?: 'Opleiding';
  capabilities: Array<Capability>;
  client: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  wordpress_id: Scalars['Int']['output'];
};

export type Opleidingsniveau = {
  __typename?: 'Opleidingsniveau';
  capabilities: Array<Capability>;
  client: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  wordpress_id: Scalars['Int']['output'];
};

export type Page = {
  __typename?: 'Page';
  active_image?: Maybe<Scalars['Image']['output']>;
  children: Array<Page>;
  images: Array<Scalars['Image']['output']>;
  parent?: Maybe<Page>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  template: Template;
  template_name: Scalars['String']['output'];
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

/** a type that describes the page content and it's metadata */
export type PageContent = {
  __typename?: 'PageContent';
  active_image?: Maybe<Scalars['Image']['output']>;
  intro?: Maybe<Scalars['String']['output']>;
  kleur?: Maybe<Scalars['String']['output']>;
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
  renderable_intro?: Maybe<Scalars['String']['output']>;
  renderable_text?: Maybe<Scalars['String']['output']>;
  secundaire_kleur?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type Pagination = {
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type PasswordRule = {
  __typename?: 'PasswordRule';
  message: Scalars['String']['output'];
  type: PasswordRuleType;
  value?: Maybe<Scalars['Mixed']['output']>;
};

export enum PasswordRuleType {
  Letters = 'Letters',
  Max = 'Max',
  Min = 'Min',
  MixedCase = 'MixedCase',
  Numbers = 'Numbers',
  Symbols = 'Symbols'
}

export type Plaats = {
  __typename?: 'Plaats';
  aantal_vacatures?: Maybe<Scalars['Int']['output']>;
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  intro?: Maybe<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** returns the arbochecklists for the arbochecklist form */
  arbochecklists: Array<Arbochecklist>;
  /** returns a list of one or more news articles */
  article: Array<Nieuwsbericht>;
  /** returns a paginated list of news articles */
  articles?: Maybe<NieuwsberichtPagination>;
  /** returns a list of one or more westerduin branches */
  branch: Array<Vestiging>;
  caoQuery: Cao;
  caosQuery: Array<Cao>;
  categories: Array<Category>;
  checkKvk: Array<KvkResult>;
  currentTenantInfo?: Maybe<Tenant_3af8041b6556776eda1312e7c8acaed7>;
  employer?: Maybe<Opdrachtgever>;
  /** returns a list of employers */
  employers: Array<Opdrachtgever>;
  form?: Maybe<Form>;
  /** returns a paginated list of documents */
  hulpmiddelen: Array<Hulpmiddel>;
  kvkBaseProfile: CompanyAddressInformation;
  /** returns a list of one or more locations */
  location: Array<Plaats>;
  locationsInRadius?: Maybe<Array<Plaats>>;
  me?: Maybe<User_07e75d37dfdb1fad1b23e74888c041b4>;
  menu?: Maybe<Menu>;
  menus: Array<Menu>;
  page?: Maybe<Page>;
  passwordRules: Array<PasswordRule>;
  /** returns a random recruiter */
  randomRecruiter?: Maybe<Recruiter>;
  /** returns a recruiter */
  recruiter?: Maybe<Recruiter>;
  /** returns a specific reference */
  reference?: Maybe<Referentie>;
  /** returns a list of one or more references */
  references?: Maybe<ReferentiePagination>;
  /** returns a list of one or more sectors */
  sector: Array<Sector>;
  settings: Array<Setting>;
  /** returns a list of sollicitatieprocessen */
  sollicitatieprocessen: Array<Sollicitatieproces>;
  /** returns a list of one or more frequently asked questions grouped by subject */
  subject: Array<Category>;
  /** returns a list of one or more vacancies */
  vacancy?: Maybe<Array<Vacancy>>;
  /** returns page specific content for sector, location entities and 5 random related vacancies */
  vacancyLandingPage?: Maybe<LandingPage>;
  /** returns a list of paginated vacancies that match the provided filters */
  vacancySearch?: Maybe<VacancyPagination>;
  /** returns a list of vacancies that match the provided filters */
  vacancySimilar: Array<Vacancy>;
};


export type QueryArticleArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryArticlesArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBranchArgs = {
  exclude?: InputMaybe<Scalars['ID']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  sortables?: InputMaybe<BranchSortables>;
};


export type QueryCaoQueryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCategoriesArgs = {
  hierarchy?: InputMaybe<Scalars['Boolean']['input']>;
  resource_id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCheckKvkArgs = {
  kvk_number: Scalars['String']['input'];
};


export type QueryEmployerArgs = {
  slug: Scalars['String']['input'];
};


export type QueryFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryKvkBaseProfileArgs = {
  branch_number?: InputMaybe<Scalars['String']['input']>;
  kvk_number: Scalars['String']['input'];
};


export type QueryLocationArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLocationsInRadiusArgs = {
  slug: Scalars['String']['input'];
};


export type QueryMenuArgs = {
  name: Scalars['String']['input'];
};


export type QueryMenusArgs = {
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryPageArgs = {
  segments?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRecruiterArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryReferenceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReferencesArgs = {
  per_page?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySectorArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySollicitatieprocessenArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySubjectArgs = {
  forEmployers?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryVacancyArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVacancyLandingPageArgs = {
  filters?: InputMaybe<VacancyFilters>;
  order?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};


export type QueryVacancySearchArgs = {
  filters?: InputMaybe<VacancyFilters>;
  order?: InputMaybe<Scalars['String']['input']>;
  order_direction?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVacancySimilarArgs = {
  filters?: InputMaybe<VacancyFilters>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
  vacancy: Scalars['ID']['input'];
};

export type Recruiter = {
  __typename?: 'Recruiter';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  client?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  department: Vestiging;
  email?: Maybe<Scalars['Email']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  linkedin?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  recruiter_id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  whatsapp?: Maybe<Scalars['String']['output']>;
};

export type Referentie = {
  __typename?: 'Referentie';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  client?: Maybe<Opdrachtgever>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  profession: Functie;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  vacancy?: Maybe<Vacancy>;
  video: Array<Scalars['File']['output']>;
};

export type ReferentiePagination = Pagination & {
  __typename?: 'ReferentiePagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data: Array<Referentie>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['Int']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Sector = {
  __typename?: 'Sector';
  aantal_vacatures?: Maybe<Scalars['Int']['output']>;
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  intro?: Maybe<Scalars['String']['output']>;
  kleur?: Maybe<Scalars['String']['output']>;
  professions?: Maybe<Array<Maybe<Functie>>>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  secundaire_kleur?: Maybe<Scalars['String']['output']>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
};

export type Seo = {
  __typename?: 'Seo';
  meta_description?: Maybe<Scalars['String']['output']>;
  meta_follow?: Maybe<Scalars['Boolean']['output']>;
  meta_index?: Maybe<Scalars['Boolean']['output']>;
  meta_title?: Maybe<Scalars['String']['output']>;
};

export type Setting = {
  __typename?: 'Setting';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Mixed']['output']>;
};

export type SignatureInput = {
  city: Scalars['String']['input'];
  date: Scalars['Date']['input'];
  function: Scalars['String']['input'];
  name: Scalars['String']['input'];
  terms: Scalars['Boolean']['input'];
};

export type Sollicitatieproces = {
  __typename?: 'Sollicitatieproces';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  omschrijving?: Maybe<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Template = TemplateAutomatisch | TemplateHome | TemplateStandaard | TemplateUitgebreid;

export type TemplateAutomatisch = {
  __typename?: 'TemplateAutomatisch';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateHome = {
  __typename?: 'TemplateHome';
  about: Scalars['String']['output'];
  about_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  referenties: Array<Referentie>;
  referenties_count?: Maybe<Scalars['Int']['output']>;
  text: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateStandaard = {
  __typename?: 'TemplateStandaard';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  text: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type TemplateUitgebreid = {
  __typename?: 'TemplateUitgebreid';
  capabilities: Array<Capability>;
  carousel: Array<Scalars['Image']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  header_color_1?: Maybe<Scalars['String']['output']>;
  header_color_2?: Maybe<Scalars['String']['output']>;
  header_image?: Maybe<Scalars['Image']['output']>;
  header_subtitle: Scalars['String']['output'];
  header_title: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  reference?: Maybe<Referentie>;
  search?: Maybe<Scalars['Boolean']['output']>;
  show_branches?: Maybe<Scalars['Boolean']['output']>;
  sidebar_dark?: Maybe<Scalars['Boolean']['output']>;
  text: Scalars['String']['output'];
  text_sidebar?: Maybe<Scalars['String']['output']>;
  text_wide?: Maybe<Scalars['String']['output']>;
  text_wide_before?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  youtube_id?: Maybe<Scalars['String']['output']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  domain: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  locales: Array<Scalars['String']['output']>;
  seo?: Maybe<Seo>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Tenant_3af8041b6556776eda1312e7c8acaed7 = {
  __typename?: 'Tenant_3af8041b6556776eda1312e7c8acaed7';
  active_image?: Maybe<Scalars['Image']['output']>;
  seo?: Maybe<Seo>;
  title: Scalars['String']['output'];
};

export type TwoFactorSecret = {
  __typename?: 'TwoFactorSecret';
  app: Scalars['String']['output'];
  email: Scalars['Email']['output'];
  secret: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  _?: Maybe<Scalars['String']['output']>;
};

export type User_07e75d37dfdb1fad1b23e74888c041b4 = {
  __typename?: 'User_07e75d37dfdb1fad1b23e74888c041b4';
  active_image?: Maybe<Scalars['Image']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['Email']['output'];
  first_name: Scalars['String']['output'];
  images: Array<Scalars['Image']['output']>;
  initials: Scalars['String']['output'];
  last_login?: Maybe<Scalars['DateTime']['output']>;
  last_name: Scalars['String']['output'];
  name: Scalars['String']['output'];
  published: Scalars['Boolean']['output'];
  /** @deprecated Use roles field instead. */
  role?: Maybe<Role>;
  roles: Array<Role>;
  tenants: Array<Tenant>;
  tenants_count?: Maybe<Scalars['Int']['output']>;
  two_factor_enabled?: Maybe<Scalars['Boolean']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['Uuid']['output'];
};


export type User_07e75d37dfdb1fad1b23e74888c041b4AvatarArgs = {
  w?: InputMaybe<Scalars['Int']['input']>;
};

/** list of utm values */
export type UtmFields = {
  utm_campaign?: InputMaybe<Scalars['String']['input']>;
  utm_content?: InputMaybe<Scalars['String']['input']>;
  utm_medium?: InputMaybe<Scalars['String']['input']>;
  utm_source?: InputMaybe<Scalars['String']['input']>;
  utm_term?: InputMaybe<Scalars['String']['input']>;
};

export type Uur = {
  __typename?: 'Uur';
  capabilities: Array<Capability>;
  client: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  wordpress_id: Scalars['Int']['output'];
};

export type Vacancy = {
  __typename?: 'Vacancy';
  capabilities: Array<Capability>;
  cid?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  client?: Maybe<Scalars['String']['output']>;
  closing_date?: Maybe<Scalars['String']['output']>;
  commissioner?: Maybe<Opdrachtgever>;
  company_description?: Maybe<Scalars['String']['output']>;
  conditions?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discipline?: Maybe<Scalars['String']['output']>;
  education_degree?: Maybe<Scalars['String']['output']>;
  employment_agency?: Maybe<Scalars['Boolean']['output']>;
  excerpt?: Maybe<Scalars['String']['output']>;
  experience?: Maybe<Scalars['String']['output']>;
  full_title?: Maybe<Scalars['String']['output']>;
  hours_per_week?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  indeed?: Maybe<Scalars['Boolean']['output']>;
  indeed_prio?: Maybe<Scalars['Boolean']['output']>;
  intern?: Maybe<Scalars['Boolean']['output']>;
  is_open?: Maybe<Scalars['Boolean']['output']>;
  is_public?: Maybe<Scalars['Boolean']['output']>;
  job_id?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  location: Array<Plaats>;
  location_count?: Maybe<Scalars['Int']['output']>;
  ms_job_number?: Maybe<Scalars['String']['output']>;
  procedure?: Maybe<Scalars['String']['output']>;
  profession: Array<Functie>;
  profession_count?: Maybe<Scalars['Int']['output']>;
  provincie?: Maybe<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  publish_on?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  recruiter?: Maybe<Recruiter>;
  requirements?: Maybe<Scalars['String']['output']>;
  salary?: Maybe<Scalars['String']['output']>;
  salary_max?: Maybe<Scalars['String']['output']>;
  salary_per_hour?: Maybe<Scalars['Float']['output']>;
  salary_unit?: Maybe<Scalars['String']['output']>;
  sector?: Maybe<Sector>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  start_date?: Maybe<Scalars['DateTime']['output']>;
  title: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  usp_s?: Maybe<Scalars['JSON']['output']>;
  utm_campaign?: Maybe<Scalars['String']['output']>;
  utm_content?: Maybe<Scalars['String']['output']>;
  utm_medium?: Maybe<Scalars['String']['output']>;
  utm_source?: Maybe<Scalars['String']['output']>;
  utm_term?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

/** list of values that vacancies can be filtered by */
export type VacancyFilters = {
  /** city in which to look for vacancies */
  city?: InputMaybe<Scalars['String']['input']>;
  /** number of hours a week */
  hours?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Whether to get intern vacancies */
  intern?: InputMaybe<Scalars['Boolean']['input']>;
  /** profession to filter on */
  profession?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** search query */
  query?: InputMaybe<Scalars['String']['input']>;
  /** distance in kilometers from location */
  radius?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  /** sector to filter on */
  sector?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type VacancyPagination = Pagination & {
  __typename?: 'VacancyPagination';
  /** Current page of the cursor */
  current_page: Scalars['Int']['output'];
  /** List of items on the current page */
  data: Array<Vacancy>;
  /** Number of the first item returned */
  from?: Maybe<Scalars['Int']['output']>;
  /** Determines if cursor has more pages after the current page */
  has_more_pages: Scalars['Boolean']['output'];
  /** The last page (number of pages) */
  last_page: Scalars['Int']['output'];
  /** Number of items returned per page */
  per_page: Scalars['Int']['output'];
  /** Number of the last item returned */
  to?: Maybe<Scalars['Int']['output']>;
  /** Number of total items selected by the query */
  total: Scalars['Int']['output'];
};

export type Vakgebied = {
  __typename?: 'Vakgebied';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  client: Scalars['String']['output'];
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  wordpress_id: Scalars['Int']['output'];
};

export type Verkoopargument = {
  __typename?: 'Verkoopargument';
  capabilities: Array<Capability>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type Vestiging = {
  __typename?: 'Vestiging';
  about?: Maybe<Scalars['String']['output']>;
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  city?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['Email']['output']>;
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  open_vacancy_id?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  recruiters?: Maybe<Array<Maybe<Recruiter>>>;
  seo?: Maybe<Seo>;
  slug: Scalars['String']['output'];
  street?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type Vraag = {
  __typename?: 'Vraag';
  active_image?: Maybe<Scalars['Image']['output']>;
  capabilities: Array<Capability>;
  categories: Array<Category>;
  created_at?: Maybe<Scalars['DateTime']['output']>;
  doelgroep: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  images: Array<Scalars['Image']['output']>;
  publish_at?: Maybe<Scalars['DateTime']['output']>;
  published: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['DateTime']['output']>;
};

export type SignUpJobAlertsMutationVariables = Exact<{
  email: Scalars['Email']['input'];
}>;


export type SignUpJobAlertsMutation = { __typename?: 'Mutation', signUpJobAlerts?: boolean | null };

export type SubmitApplicationMutationVariables = Exact<{
  vacancy_id?: InputMaybe<Scalars['ID']['input']>;
  first_name?: InputMaybe<Scalars['String']['input']>;
  last_name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  resume?: InputMaybe<Scalars['Upload']['input']>;
  mailings?: InputMaybe<Scalars['Boolean']['input']>;
  team?: InputMaybe<Scalars['String']['input']>;
  utm?: InputMaybe<UtmFields>;
}>;


export type SubmitApplicationMutation = { __typename?: 'Mutation', submitApplication?: { __typename?: 'Application', first_name: string, title: string } | null };

export type SubmitArbochecklistRegistrationMutationVariables = Exact<{
  arbochecklist: Scalars['ID']['input'];
  email: Scalars['Email']['input'];
  attachment: Scalars['Upload']['input'];
}>;


export type SubmitArbochecklistRegistrationMutation = { __typename?: 'Mutation', submitArbochecklistRegistration?: { __typename?: 'Application', title: string } | null };

export type SubmitFormMutationVariables = Exact<{
  form_id: Scalars['ID']['input'];
  form_data: Scalars['String']['input'];
  extra?: InputMaybe<Scalars['String']['input']>;
}>;


export type SubmitFormMutation = { __typename?: 'Mutation', submitForm: { __typename?: 'FormSubmitResponse', confirm_url?: string | null } };

export type SubmitInlenersFormMutationVariables = Exact<{
  kvk: KvkInput;
  address: AddressInput;
  invoice: InvoicingInput;
  hours: HoursInput;
  additionalInformation: InlenerInput;
  signature: SignatureInput;
}>;


export type SubmitInlenersFormMutation = { __typename?: 'Mutation', submitInlenersForm?: null | null };

export type ArbochecklistsQueryVariables = Exact<{ [key: string]: never; }>;


export type ArbochecklistsQuery = { __typename?: 'Query', arbochecklists: Array<{ __typename?: 'Arbochecklist', id: number, title: string, attachment: Array<File> }> };

export type ArticleQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
}>;


export type ArticleQuery = { __typename?: 'Query', article: Array<{ __typename?: 'Nieuwsbericht', id: number, slug: string, active_image?: Image | null, title: string, summary?: string | null, text: string, created_at?: string | null, employeeName?: string | null, employeePortrait?: Image | null, employeeOccupation?: string | null }> };

export type ArticlesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']['input']>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ArticlesQuery = { __typename?: 'Query', articles?: { __typename?: 'NieuwsberichtPagination', last_page: number, total: number, data: Array<{ __typename?: 'Nieuwsbericht', id: number, slug: string, active_image?: Image | null, title: string, summary?: string | null, text: string, created_at?: string | null }> } | null };

export type BranchQueryVariables = Exact<{
  sortables?: InputMaybe<BranchSortables>;
  slug?: InputMaybe<Scalars['String']['input']>;
  exclude?: InputMaybe<Scalars['ID']['input']>;
}>;


export type BranchQuery = { __typename?: 'Query', branch: Array<{ __typename?: 'Vestiging', id: number, slug: string, title: string, about?: string | null, active_image?: Image | null, street?: string | null, zip?: string | null, city?: string | null, email?: string | null, phone?: string | null, team?: string | null, recruiters?: Array<{ __typename?: 'Recruiter', active_image?: Image | null, title: string, role?: string | null } | null> | null }> };

export type CaoQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CaoQuery = { __typename?: 'Query', caoQuery: { __typename?: 'Cao', title: string, irregular_hours?: string | null, overtime?: string | null, physical_conditions?: string | null, shift_work?: string | null, initial_pay_raise?: string | null, periodic_salary_increase?: string | null, payment?: string | null, expense_reimbursement?: string | null, travel_time_allowance?: string | null, adv?: string | null, kostenvergoedingen?: string | null, working_hours: Array<string> } };

export type CaosQueryVariables = Exact<{ [key: string]: never; }>;


export type CaosQuery = { __typename?: 'Query', caosQuery: Array<{ __typename?: 'Cao', id: number, title: string }> };

export type CheckKvkQueryVariables = Exact<{
  kvk_number: Scalars['String']['input'];
}>;


export type CheckKvkQuery = { __typename?: 'Query', checkKvk: Array<{ __typename?: 'KvkResult', company_name?: string | null, kvk_number?: string | null, branch_number?: string | null, type: string }> };

export type EmployerQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type EmployerQuery = { __typename?: 'Query', employer?: { __typename?: 'Opdrachtgever', id: number, slug: string, active_image?: Image | null, color_code?: string | null, title: string, alias?: string | null, logo?: Image | null, intro?: string | null, text?: string | null, street?: string | null, zip?: string | null, city?: string | null, email?: string | null, phone?: string | null, usps: Array<{ __typename?: 'Verkoopargument', id: number, title: string, text: string }>, vacancies?: Array<{ __typename?: 'Vacancy', id: number, slug: string, title: string, city?: string | null, salary?: string | null, salary_unit?: string | null, hours_per_week?: string | null, usps?: any | null, sector?: { __typename?: 'Sector', title: string, slug: string } | null, commissioner?: { __typename?: 'Opdrachtgever', published: boolean, references: Array<{ __typename?: 'Referentie', text: string, title: string, image?: Image | null, profession: { __typename?: 'Functie', title: string } }> } | null } | null> | null, references: Array<{ __typename?: 'Referentie', title: string, text: string, video: Array<File>, active_image?: Image | null, client?: { __typename?: 'Opdrachtgever', slug: string, active_image?: Image | null, title: string, alias?: string | null } | null, profession: { __typename?: 'Functie', title: string } }>, recruiter?: { __typename?: 'Recruiter', title: string, role?: string | null, phone?: string | null, whatsapp?: string | null, email?: string | null, active_image?: Image | null } | null, questions: Array<{ __typename?: 'Vraag', title: string, text: string }> } | null };

export type EmployersQueryVariables = Exact<{ [key: string]: never; }>;


export type EmployersQuery = { __typename?: 'Query', employers: Array<{ __typename?: 'Opdrachtgever', id: number, logo?: Image | null, slug: string }> };

export type HulpmiddelenQueryVariables = Exact<{ [key: string]: never; }>;


export type HulpmiddelenQuery = { __typename?: 'Query', hulpmiddelen: Array<{ __typename?: 'Hulpmiddel', id: number, title: string, document: Array<File>, url?: string | null }> };

export type KvkBaseProfileQueryVariables = Exact<{
  kvk_number: Scalars['String']['input'];
  branch_number?: InputMaybe<Scalars['String']['input']>;
}>;


export type KvkBaseProfileQuery = { __typename?: 'Query', kvkBaseProfile: { __typename?: 'CompanyAddressInformation', company_name?: string | null, legal_form?: string | null, address?: { __typename?: 'Address', title: string, house_number?: string | null, house_number_suffix?: string | null, zipcode?: string | null, city?: string | null, country?: string | null } | null } };

export type LocationQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
}>;


export type LocationQuery = { __typename?: 'Query', location: Array<{ __typename?: 'Plaats', active_image?: Image | null, title: string, text?: string | null, slug: string }> };

export type LocationsInRadiusQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;


export type LocationsInRadiusQuery = { __typename?: 'Query', locationsInRadius?: Array<{ __typename?: 'Plaats', active_image?: Image | null, title: string, text?: string | null, slug: string }> | null };

export type RandomRecruiterQueryVariables = Exact<{ [key: string]: never; }>;


export type RandomRecruiterQuery = { __typename?: 'Query', randomRecruiter?: { __typename?: 'Recruiter', id: number, active_image?: Image | null, email?: string | null, phone?: string | null, role?: string | null, title: string } | null };

export type RecruiterQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type RecruiterQuery = { __typename?: 'Query', recruiter?: { __typename?: 'Recruiter', id: number, active_image?: Image | null, title: string, email?: string | null, phone?: string | null, whatsapp?: string | null, role?: string | null } | null };

export type ReferenceQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ReferenceQuery = { __typename?: 'Query', reference?: { __typename?: 'Referentie', id: number, active_image?: Image | null, title: string, text: string, video: Array<File>, profession: { __typename?: 'Functie', title: string }, client?: { __typename?: 'Opdrachtgever', slug: string, title: string, alias?: string | null, active_image?: Image | null, logo?: Image | null } | null, vacancy?: { __typename?: 'Vacancy', id: number, title: string, description?: string | null, company_description?: string | null, requirements?: string | null, conditions?: string | null, hours_per_week?: string | null, salary?: string | null, salary_max?: string | null, salary_unit?: string | null, location: Array<{ __typename?: 'Plaats', title: string }>, recruiter?: { __typename?: 'Recruiter', id: number, active_image?: Image | null, title: string, email?: string | null, phone?: string | null, role?: string | null } | null, commissioner?: { __typename?: 'Opdrachtgever', references: Array<{ __typename?: 'Referentie', text: string, profession: { __typename?: 'Functie', title: string } }> } | null, sector?: { __typename?: 'Sector', title: string, kleur?: string | null, secundaire_kleur?: string | null } | null } | null } | null };

export type ReferencesQueryVariables = Exact<{
  per_page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type ReferencesQuery = { __typename?: 'Query', references?: { __typename?: 'ReferentiePagination', total: number, data: Array<{ __typename?: 'Referentie', id: number, active_image?: Image | null, title: string, text: string, video: Array<File>, profession: { __typename?: 'Functie', title: string }, client?: { __typename?: 'Opdrachtgever', published: boolean, slug: string, title: string, alias?: string | null, active_image?: Image | null, logo?: Image | null } | null, vacancy?: { __typename?: 'Vacancy', id: number } | null }> } | null };

export type SectorQueryVariables = Exact<{ [key: string]: never; }>;


export type SectorQuery = { __typename?: 'Query', sector: Array<{ __typename?: 'Sector', id: number, active_image?: Image | null, title: string, text?: string | null, slug: string, color?: string | null, professions?: Array<{ __typename?: 'Functie', label: string, value: string } | null> | null }> };

export type SectorFilterQueryVariables = Exact<{ [key: string]: never; }>;


export type SectorFilterQuery = { __typename?: 'Query', sector: Array<{ __typename?: 'Sector', value: string, label: string }> };

export type PageQueryVariables = Exact<{
  segments: Scalars['String']['input'];
}>;


export type PageQuery = { __typename?: 'Query', page?: { __typename?: 'Page', title: string, slug: string, active_image?: Image | null, images: Array<Image>, template_name: string, square?: Image | null, template: { __typename?: 'TemplateAutomatisch' } | { __typename?: 'TemplateHome', text: string, about: string, about_image?: Image | null, references: Array<{ __typename?: 'Referentie', id: number, active_image?: Image | null, title: string, text: string, video: Array<File>, profession: { __typename?: 'Functie', title: string }, client?: { __typename?: 'Opdrachtgever', published: boolean, slug: string, alias?: string | null, title: string, active_image?: Image | null, logo?: Image | null } | null }> } | { __typename?: 'TemplateStandaard', text: string } | { __typename?: 'TemplateUitgebreid', text: string, text_sidebar?: string | null, search?: boolean | null, sidebar_dark?: boolean | null, carousel: Array<Image>, show_branches?: boolean | null, header_color_1?: string | null, header_color_2?: string | null, header_image?: Image | null, header_subtitle: string, header_title: string, youtube_id?: string | null, textWideBefore?: string | null, textWide?: string | null, reference?: { __typename?: 'Referentie', title: string, text: string, profession: { __typename?: 'Functie', title: string } } | null }, children: Array<{ __typename?: 'Page', title: string, url: string, children: Array<{ __typename?: 'Page', title: string, url: string }> }>, parent?: { __typename?: 'Page', title: string, url: string, children: Array<{ __typename?: 'Page', title: string, url: string, children: Array<{ __typename?: 'Page', title: string, url: string }> }> } | null, seo?: { __typename?: 'Seo', meta_title?: string | null, meta_description?: string | null, meta_index?: boolean | null, meta_follow?: boolean | null } | null } | null };

export type SollicitatieprocessenQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type SollicitatieprocessenQuery = { __typename?: 'Query', sollicitatieprocessen: Array<{ __typename?: 'Sollicitatieproces', title: string, slug: string, omschrijving?: string | null, active_image?: Image | null }> };

export type SubjectQueryVariables = Exact<{
  q?: InputMaybe<Scalars['String']['input']>;
  subjects?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  forEmployers?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SubjectQuery = { __typename?: 'Query', subject: Array<{ __typename?: 'Category', id?: string | null, active_image?: Image | null, name?: string | null, text?: string | null, questions?: Array<{ __typename?: 'Vraag', id: number, title: string, text: string } | null> | null }> };

export type SubjectFilterQueryVariables = Exact<{
  forEmployers?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SubjectFilterQuery = { __typename?: 'Query', subject: Array<{ __typename?: 'Category', value?: string | null, label?: string | null }> };

export type VacancyQueryVariables = Exact<{
  slug?: InputMaybe<Scalars['String']['input']>;
}>;


export type VacancyQuery = { __typename?: 'Query', vacancy?: Array<{ __typename?: 'Vacancy', id: number, title: string, slug: string, description?: string | null, company_description?: string | null, requirements?: string | null, conditions?: string | null, procedure?: string | null, city?: string | null, client?: string | null, hours_per_week?: string | null, salary?: string | null, salary_max?: string | null, salary_unit?: string | null, salary_per_hour?: number | null, ms_job_number?: string | null, location: Array<{ __typename?: 'Plaats', title: string }>, recruiter?: { __typename?: 'Recruiter', id: number, active_image?: Image | null, title: string, email?: string | null, phone?: string | null, whatsapp?: string | null, role?: string | null, department: { __typename?: 'Vestiging', id: number, title: string } } | null, commissioner?: { __typename?: 'Opdrachtgever', id: number, published: boolean, slug: string, references: Array<{ __typename?: 'Referentie', text: string, profession: { __typename?: 'Functie', title: string } }> } | null, sector?: { __typename?: 'Sector', title: string, slug: string, kleur?: string | null, secundaire_kleur?: string | null } | null }> | null };

export type VacancyLandingPageQueryVariables = Exact<{
  slug: Scalars['String']['input'];
  filters?: InputMaybe<VacancyFilters>;
  order?: InputMaybe<Scalars['String']['input']>;
}>;


export type VacancyLandingPageQuery = { __typename?: 'Query', vacancyLandingPage?: { __typename?: 'LandingPage', page?: { __typename?: 'PageContent', active_image?: Image | null, title?: string | null, intro?: string | null, renderable_intro?: string | null, renderable_text?: string | null, text?: string | null, type?: string | null, meta_title?: string | null, meta_description?: string | null, kleur?: string | null, secundaire_kleur?: string | null } | null, sector?: { __typename?: 'Sector', title: string, active_image?: Image | null, kleur?: string | null, secundaire_kleur?: string | null } | null, professions?: Array<{ __typename?: 'Functie', title: string, active_image?: Image | null, slug: string } | null> | null, vacancies?: Array<{ __typename?: 'Vacancy', id: number, slug: string, title: string, city?: string | null, hours_per_week?: string | null, salary?: string | null, salary_max?: string | null, salary_unit?: string | null, salary_per_hour?: number | null, requirements?: string | null, excerpt?: string | null, usps?: any | null, location: Array<{ __typename?: 'Plaats', published: boolean }>, commissioner?: { __typename?: 'Opdrachtgever', slug: string, id: number, published: boolean } | null } | null> | null, recruiter?: { __typename?: 'Recruiter', id: number, title: string, role?: string | null, active_image?: Image | null, email?: string | null, phone?: string | null } | null } | null };

export type VacancySearchQueryVariables = Exact<{
  filters?: InputMaybe<VacancyFilters>;
  order?: InputMaybe<Scalars['String']['input']>;
  order_direction?: InputMaybe<Scalars['String']['input']>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;


export type VacancySearchQuery = { __typename?: 'Query', vacancySearch?: { __typename?: 'VacancyPagination', last_page: number, total: number, data: Array<{ __typename?: 'Vacancy', id: number, slug: string, title: string, city?: string | null, hours_per_week?: string | null, salary?: string | null, salary_max?: string | null, salary_unit?: string | null, salary_per_hour?: number | null, requirements?: string | null, excerpt?: string | null, usps?: any | null, location: Array<{ __typename?: 'Plaats', published: boolean }>, recruiter?: { __typename?: 'Recruiter', id: number, title: string, role?: string | null, active_image?: Image | null, email?: string | null, phone?: string | null } | null, commissioner?: { __typename?: 'Opdrachtgever', id: number, published: boolean, slug: string, references: Array<{ __typename?: 'Referentie', image?: Image | null }> } | null }> } | null };

export type VacancySimilarQueryVariables = Exact<{
  filters?: InputMaybe<VacancyFilters>;
  per_page?: InputMaybe<Scalars['Int']['input']>;
  vacancy: Scalars['ID']['input'];
}>;


export type VacancySimilarQuery = { __typename?: 'Query', vacancySimilar: Array<{ __typename?: 'Vacancy', id: number, slug: string, title: string, city?: string | null, hours_per_week?: string | null, salary?: string | null, salary_max?: string | null, salary_unit?: string | null, salary_per_hour?: number | null, requirements?: string | null, commissioner?: { __typename?: 'Opdrachtgever', id: number, slug: string, published: boolean } | null, recruiter?: { __typename?: 'Recruiter', id: number, title: string, role?: string | null, active_image?: Image | null, email?: string | null, phone?: string | null } | null }> };


export const SignUpJobAlertsDocument = gql`
    mutation signUpJobAlerts($email: Email!) {
  signUpJobAlerts(email: $email)
}
    `;

/**
 * __useSignUpJobAlertsMutation__
 *
 * To run a mutation, you first call `useSignUpJobAlertsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSignUpJobAlertsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSignUpJobAlertsMutation({
 *   variables: {
 *     email: // value for 'email'
 *   },
 * });
 */
export function useSignUpJobAlertsMutation(options: VueApolloComposable.UseMutationOptions<SignUpJobAlertsMutation, SignUpJobAlertsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SignUpJobAlertsMutation, SignUpJobAlertsMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SignUpJobAlertsMutation, SignUpJobAlertsMutationVariables>(SignUpJobAlertsDocument, options);
}
export type SignUpJobAlertsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SignUpJobAlertsMutation, SignUpJobAlertsMutationVariables>;
export const SubmitApplicationDocument = gql`
    mutation submitApplication($vacancy_id: ID, $first_name: String, $last_name: String, $email: Email, $phone: String, $resume: Upload, $mailings: Boolean, $team: String, $utm: UtmFields) {
  submitApplication(
    vacancy_id: $vacancy_id
    first_name: $first_name
    last_name: $last_name
    email: $email
    phone: $phone
    resume: $resume
    mailings: $mailings
    team: $team
    utm: $utm
  ) {
    first_name
    title
  }
}
    `;

/**
 * __useSubmitApplicationMutation__
 *
 * To run a mutation, you first call `useSubmitApplicationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSubmitApplicationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSubmitApplicationMutation({
 *   variables: {
 *     vacancy_id: // value for 'vacancy_id'
 *     first_name: // value for 'first_name'
 *     last_name: // value for 'last_name'
 *     email: // value for 'email'
 *     phone: // value for 'phone'
 *     resume: // value for 'resume'
 *     mailings: // value for 'mailings'
 *     team: // value for 'team'
 *     utm: // value for 'utm'
 *   },
 * });
 */
export function useSubmitApplicationMutation(options: VueApolloComposable.UseMutationOptions<SubmitApplicationMutation, SubmitApplicationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SubmitApplicationMutation, SubmitApplicationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SubmitApplicationMutation, SubmitApplicationMutationVariables>(SubmitApplicationDocument, options);
}
export type SubmitApplicationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SubmitApplicationMutation, SubmitApplicationMutationVariables>;
export const SubmitArbochecklistRegistrationDocument = gql`
    mutation submitArbochecklistRegistration($arbochecklist: ID!, $email: Email!, $attachment: Upload!) {
  submitArbochecklistRegistration(
    arbochecklist: $arbochecklist
    email: $email
    attachment: $attachment
  ) {
    title
  }
}
    `;

/**
 * __useSubmitArbochecklistRegistrationMutation__
 *
 * To run a mutation, you first call `useSubmitArbochecklistRegistrationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSubmitArbochecklistRegistrationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSubmitArbochecklistRegistrationMutation({
 *   variables: {
 *     arbochecklist: // value for 'arbochecklist'
 *     email: // value for 'email'
 *     attachment: // value for 'attachment'
 *   },
 * });
 */
export function useSubmitArbochecklistRegistrationMutation(options: VueApolloComposable.UseMutationOptions<SubmitArbochecklistRegistrationMutation, SubmitArbochecklistRegistrationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SubmitArbochecklistRegistrationMutation, SubmitArbochecklistRegistrationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SubmitArbochecklistRegistrationMutation, SubmitArbochecklistRegistrationMutationVariables>(SubmitArbochecklistRegistrationDocument, options);
}
export type SubmitArbochecklistRegistrationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SubmitArbochecklistRegistrationMutation, SubmitArbochecklistRegistrationMutationVariables>;
export const SubmitFormDocument = gql`
    mutation submitForm($form_id: ID!, $form_data: String!, $extra: String) {
  submitForm(form_id: $form_id, form_data: $form_data, extra: $extra) {
    confirm_url
  }
}
    `;

/**
 * __useSubmitFormMutation__
 *
 * To run a mutation, you first call `useSubmitFormMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFormMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSubmitFormMutation({
 *   variables: {
 *     form_id: // value for 'form_id'
 *     form_data: // value for 'form_data'
 *     extra: // value for 'extra'
 *   },
 * });
 */
export function useSubmitFormMutation(options: VueApolloComposable.UseMutationOptions<SubmitFormMutation, SubmitFormMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SubmitFormMutation, SubmitFormMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SubmitFormMutation, SubmitFormMutationVariables>(SubmitFormDocument, options);
}
export type SubmitFormMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SubmitFormMutation, SubmitFormMutationVariables>;
export const SubmitInlenersFormDocument = gql`
    mutation submitInlenersForm($kvk: KvkInput!, $address: AddressInput!, $invoice: InvoicingInput!, $hours: HoursInput!, $additionalInformation: InlenerInput!, $signature: SignatureInput!) {
  submitInlenersForm(
    kvk: $kvk
    address: $address
    invoice: $invoice
    hours: $hours
    additionalInformation: $additionalInformation
    signature: $signature
  )
}
    `;

/**
 * __useSubmitInlenersFormMutation__
 *
 * To run a mutation, you first call `useSubmitInlenersFormMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSubmitInlenersFormMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSubmitInlenersFormMutation({
 *   variables: {
 *     kvk: // value for 'kvk'
 *     address: // value for 'address'
 *     invoice: // value for 'invoice'
 *     hours: // value for 'hours'
 *     additionalInformation: // value for 'additionalInformation'
 *     signature: // value for 'signature'
 *   },
 * });
 */
export function useSubmitInlenersFormMutation(options: VueApolloComposable.UseMutationOptions<SubmitInlenersFormMutation, SubmitInlenersFormMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SubmitInlenersFormMutation, SubmitInlenersFormMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<SubmitInlenersFormMutation, SubmitInlenersFormMutationVariables>(SubmitInlenersFormDocument, options);
}
export type SubmitInlenersFormMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SubmitInlenersFormMutation, SubmitInlenersFormMutationVariables>;
export const ArbochecklistsDocument = gql`
    query arbochecklists {
  arbochecklists {
    id
    title
    attachment
  }
}
    `;

/**
 * __useArbochecklistsQuery__
 *
 * To run a query within a Vue component, call `useArbochecklistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useArbochecklistsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useArbochecklistsQuery();
 */
export function useArbochecklistsQuery(options: VueApolloComposable.UseQueryOptions<ArbochecklistsQuery, ArbochecklistsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArbochecklistsQuery, ArbochecklistsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArbochecklistsQuery, ArbochecklistsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ArbochecklistsQuery, ArbochecklistsQueryVariables>(ArbochecklistsDocument, {}, options);
}
export function useArbochecklistsLazyQuery(options: VueApolloComposable.UseQueryOptions<ArbochecklistsQuery, ArbochecklistsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArbochecklistsQuery, ArbochecklistsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArbochecklistsQuery, ArbochecklistsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ArbochecklistsQuery, ArbochecklistsQueryVariables>(ArbochecklistsDocument, {}, options);
}
export type ArbochecklistsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ArbochecklistsQuery, ArbochecklistsQueryVariables>;
export const ArticleDocument = gql`
    query article($slug: String) {
  article(slug: $slug) {
    id
    slug
    active_image
    title
    summary
    text
    created_at
    employeeName: employee_name
    employeePortrait: employee_portrait
    employeeOccupation: employee_occupation
  }
}
    `;

/**
 * __useArticleQuery__
 *
 * To run a query within a Vue component, call `useArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useArticleQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useArticleQuery(variables: ArticleQueryVariables | VueCompositionApi.Ref<ArticleQueryVariables> | ReactiveFunction<ArticleQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, variables, options);
}
export function useArticleLazyQuery(variables: ArticleQueryVariables | VueCompositionApi.Ref<ArticleQueryVariables> | ReactiveFunction<ArticleQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticleQuery, ArticleQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, variables, options);
}
export type ArticleQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ArticleQuery, ArticleQueryVariables>;
export const ArticlesDocument = gql`
    query articles($page: Int, $per_page: Int) {
  articles(page: $page, per_page: $per_page) {
    data {
      id
      slug
      active_image
      title
      summary
      text
      created_at
    }
    last_page
    total
  }
}
    `;

/**
 * __useArticlesQuery__
 *
 * To run a query within a Vue component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useArticlesQuery({
 *   page: // value for 'page'
 *   per_page: // value for 'per_page'
 * });
 */
export function useArticlesQuery(variables: ArticlesQueryVariables | VueCompositionApi.Ref<ArticlesQueryVariables> | ReactiveFunction<ArticlesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, variables, options);
}
export function useArticlesLazyQuery(variables: ArticlesQueryVariables | VueCompositionApi.Ref<ArticlesQueryVariables> | ReactiveFunction<ArticlesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ArticlesQuery, ArticlesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, variables, options);
}
export type ArticlesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ArticlesQuery, ArticlesQueryVariables>;
export const BranchDocument = gql`
    query branch($sortables: BranchSortables, $slug: String, $exclude: ID) {
  branch(sortables: $sortables, slug: $slug, exclude: $exclude) {
    id
    slug
    title
    about
    active_image
    street
    zip
    city
    email
    phone
    team
    recruiters {
      active_image
      title
      role
    }
  }
}
    `;

/**
 * __useBranchQuery__
 *
 * To run a query within a Vue component, call `useBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useBranchQuery({
 *   sortables: // value for 'sortables'
 *   slug: // value for 'slug'
 *   exclude: // value for 'exclude'
 * });
 */
export function useBranchQuery(variables: BranchQueryVariables | VueCompositionApi.Ref<BranchQueryVariables> | ReactiveFunction<BranchQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<BranchQuery, BranchQueryVariables>(BranchDocument, variables, options);
}
export function useBranchLazyQuery(variables: BranchQueryVariables | VueCompositionApi.Ref<BranchQueryVariables> | ReactiveFunction<BranchQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<BranchQuery, BranchQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<BranchQuery, BranchQueryVariables>(BranchDocument, variables, options);
}
export type BranchQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<BranchQuery, BranchQueryVariables>;
export const CaoDocument = gql`
    query cao($id: ID!) {
  caoQuery(id: $id) {
    title
    irregular_hours
    overtime
    physical_conditions
    shift_work
    initial_pay_raise
    periodic_salary_increase
    payment
    expense_reimbursement
    travel_time_allowance
    adv
    kostenvergoedingen
    working_hours
  }
}
    `;

/**
 * __useCaoQuery__
 *
 * To run a query within a Vue component, call `useCaoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaoQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCaoQuery({
 *   id: // value for 'id'
 * });
 */
export function useCaoQuery(variables: CaoQueryVariables | VueCompositionApi.Ref<CaoQueryVariables> | ReactiveFunction<CaoQueryVariables>, options: VueApolloComposable.UseQueryOptions<CaoQuery, CaoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CaoQuery, CaoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CaoQuery, CaoQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CaoQuery, CaoQueryVariables>(CaoDocument, variables, options);
}
export function useCaoLazyQuery(variables?: CaoQueryVariables | VueCompositionApi.Ref<CaoQueryVariables> | ReactiveFunction<CaoQueryVariables>, options: VueApolloComposable.UseQueryOptions<CaoQuery, CaoQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CaoQuery, CaoQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CaoQuery, CaoQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CaoQuery, CaoQueryVariables>(CaoDocument, variables, options);
}
export type CaoQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CaoQuery, CaoQueryVariables>;
export const CaosDocument = gql`
    query caos {
  caosQuery {
    id
    title
  }
}
    `;

/**
 * __useCaosQuery__
 *
 * To run a query within a Vue component, call `useCaosQuery` and pass it any options that fit your needs.
 * When your component renders, `useCaosQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCaosQuery();
 */
export function useCaosQuery(options: VueApolloComposable.UseQueryOptions<CaosQuery, CaosQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CaosQuery, CaosQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CaosQuery, CaosQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CaosQuery, CaosQueryVariables>(CaosDocument, {}, options);
}
export function useCaosLazyQuery(options: VueApolloComposable.UseQueryOptions<CaosQuery, CaosQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CaosQuery, CaosQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CaosQuery, CaosQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CaosQuery, CaosQueryVariables>(CaosDocument, {}, options);
}
export type CaosQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CaosQuery, CaosQueryVariables>;
export const CheckKvkDocument = gql`
    query checkKvk($kvk_number: String!) {
  checkKvk(kvk_number: $kvk_number) {
    company_name
    kvk_number
    branch_number
    type
  }
}
    `;

/**
 * __useCheckKvkQuery__
 *
 * To run a query within a Vue component, call `useCheckKvkQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckKvkQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCheckKvkQuery({
 *   kvk_number: // value for 'kvk_number'
 * });
 */
export function useCheckKvkQuery(variables: CheckKvkQueryVariables | VueCompositionApi.Ref<CheckKvkQueryVariables> | ReactiveFunction<CheckKvkQueryVariables>, options: VueApolloComposable.UseQueryOptions<CheckKvkQuery, CheckKvkQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CheckKvkQuery, CheckKvkQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CheckKvkQuery, CheckKvkQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CheckKvkQuery, CheckKvkQueryVariables>(CheckKvkDocument, variables, options);
}
export function useCheckKvkLazyQuery(variables?: CheckKvkQueryVariables | VueCompositionApi.Ref<CheckKvkQueryVariables> | ReactiveFunction<CheckKvkQueryVariables>, options: VueApolloComposable.UseQueryOptions<CheckKvkQuery, CheckKvkQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CheckKvkQuery, CheckKvkQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CheckKvkQuery, CheckKvkQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CheckKvkQuery, CheckKvkQueryVariables>(CheckKvkDocument, variables, options);
}
export type CheckKvkQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CheckKvkQuery, CheckKvkQueryVariables>;
export const EmployerDocument = gql`
    query employer($slug: String!) {
  employer(slug: $slug) {
    id
    slug
    active_image
    color_code
    title
    alias
    logo
    intro
    text
    street
    zip
    city
    email
    phone
    usps {
      id
      title
      text
    }
    vacancies {
      id
      slug
      title
      city
      salary
      salary_unit
      hours_per_week
      usps: usp_s
      sector {
        title
        slug
      }
      commissioner {
        published
        references {
          image: active_image
          text
          title
          profession {
            title
          }
        }
      }
    }
    references {
      title
      text
      video
      active_image
      client {
        slug
        active_image
        title
        alias
      }
      profession {
        title
      }
    }
    recruiter {
      title
      role
      phone
      whatsapp
      email
      active_image
    }
    questions {
      title
      text
    }
  }
}
    `;

/**
 * __useEmployerQuery__
 *
 * To run a query within a Vue component, call `useEmployerQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployerQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEmployerQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useEmployerQuery(variables: EmployerQueryVariables | VueCompositionApi.Ref<EmployerQueryVariables> | ReactiveFunction<EmployerQueryVariables>, options: VueApolloComposable.UseQueryOptions<EmployerQuery, EmployerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EmployerQuery, EmployerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EmployerQuery, EmployerQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EmployerQuery, EmployerQueryVariables>(EmployerDocument, variables, options);
}
export function useEmployerLazyQuery(variables?: EmployerQueryVariables | VueCompositionApi.Ref<EmployerQueryVariables> | ReactiveFunction<EmployerQueryVariables>, options: VueApolloComposable.UseQueryOptions<EmployerQuery, EmployerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EmployerQuery, EmployerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EmployerQuery, EmployerQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<EmployerQuery, EmployerQueryVariables>(EmployerDocument, variables, options);
}
export type EmployerQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EmployerQuery, EmployerQueryVariables>;
export const EmployersDocument = gql`
    query employers @cache(ttl: 60, public: true) {
  employers {
    id
    logo
    slug
  }
}
    `;

/**
 * __useEmployersQuery__
 *
 * To run a query within a Vue component, call `useEmployersQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useEmployersQuery();
 */
export function useEmployersQuery(options: VueApolloComposable.UseQueryOptions<EmployersQuery, EmployersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EmployersQuery, EmployersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EmployersQuery, EmployersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<EmployersQuery, EmployersQueryVariables>(EmployersDocument, {}, options);
}
export function useEmployersLazyQuery(options: VueApolloComposable.UseQueryOptions<EmployersQuery, EmployersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<EmployersQuery, EmployersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<EmployersQuery, EmployersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<EmployersQuery, EmployersQueryVariables>(EmployersDocument, {}, options);
}
export type EmployersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<EmployersQuery, EmployersQueryVariables>;
export const HulpmiddelenDocument = gql`
    query hulpmiddelen @cache(ttl: 60, public: true) {
  hulpmiddelen {
    id
    title
    document
    url
  }
}
    `;

/**
 * __useHulpmiddelenQuery__
 *
 * To run a query within a Vue component, call `useHulpmiddelenQuery` and pass it any options that fit your needs.
 * When your component renders, `useHulpmiddelenQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useHulpmiddelenQuery();
 */
export function useHulpmiddelenQuery(options: VueApolloComposable.UseQueryOptions<HulpmiddelenQuery, HulpmiddelenQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<HulpmiddelenQuery, HulpmiddelenQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<HulpmiddelenQuery, HulpmiddelenQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<HulpmiddelenQuery, HulpmiddelenQueryVariables>(HulpmiddelenDocument, {}, options);
}
export function useHulpmiddelenLazyQuery(options: VueApolloComposable.UseQueryOptions<HulpmiddelenQuery, HulpmiddelenQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<HulpmiddelenQuery, HulpmiddelenQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<HulpmiddelenQuery, HulpmiddelenQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<HulpmiddelenQuery, HulpmiddelenQueryVariables>(HulpmiddelenDocument, {}, options);
}
export type HulpmiddelenQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<HulpmiddelenQuery, HulpmiddelenQueryVariables>;
export const KvkBaseProfileDocument = gql`
    query kvkBaseProfile($kvk_number: String!, $branch_number: String) {
  kvkBaseProfile(kvk_number: $kvk_number, branch_number: $branch_number) {
    company_name
    legal_form
    address {
      title
      house_number
      house_number_suffix
      zipcode
      city
      country
    }
  }
}
    `;

/**
 * __useKvkBaseProfileQuery__
 *
 * To run a query within a Vue component, call `useKvkBaseProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useKvkBaseProfileQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useKvkBaseProfileQuery({
 *   kvk_number: // value for 'kvk_number'
 *   branch_number: // value for 'branch_number'
 * });
 */
export function useKvkBaseProfileQuery(variables: KvkBaseProfileQueryVariables | VueCompositionApi.Ref<KvkBaseProfileQueryVariables> | ReactiveFunction<KvkBaseProfileQueryVariables>, options: VueApolloComposable.UseQueryOptions<KvkBaseProfileQuery, KvkBaseProfileQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<KvkBaseProfileQuery, KvkBaseProfileQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<KvkBaseProfileQuery, KvkBaseProfileQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<KvkBaseProfileQuery, KvkBaseProfileQueryVariables>(KvkBaseProfileDocument, variables, options);
}
export function useKvkBaseProfileLazyQuery(variables?: KvkBaseProfileQueryVariables | VueCompositionApi.Ref<KvkBaseProfileQueryVariables> | ReactiveFunction<KvkBaseProfileQueryVariables>, options: VueApolloComposable.UseQueryOptions<KvkBaseProfileQuery, KvkBaseProfileQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<KvkBaseProfileQuery, KvkBaseProfileQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<KvkBaseProfileQuery, KvkBaseProfileQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<KvkBaseProfileQuery, KvkBaseProfileQueryVariables>(KvkBaseProfileDocument, variables, options);
}
export type KvkBaseProfileQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<KvkBaseProfileQuery, KvkBaseProfileQueryVariables>;
export const LocationDocument = gql`
    query location($slug: String) @cache(ttl: 60, public: true) {
  location(slug: $slug) {
    active_image
    title
    text
    slug
  }
}
    `;

/**
 * __useLocationQuery__
 *
 * To run a query within a Vue component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLocationQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useLocationQuery(variables: LocationQueryVariables | VueCompositionApi.Ref<LocationQueryVariables> | ReactiveFunction<LocationQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, variables, options);
}
export function useLocationLazyQuery(variables: LocationQueryVariables | VueCompositionApi.Ref<LocationQueryVariables> | ReactiveFunction<LocationQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LocationQuery, LocationQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, variables, options);
}
export type LocationQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<LocationQuery, LocationQueryVariables>;
export const LocationsInRadiusDocument = gql`
    query locationsInRadius($slug: String!) {
  locationsInRadius(slug: $slug) {
    active_image
    title
    text
    slug
  }
}
    `;

/**
 * __useLocationsInRadiusQuery__
 *
 * To run a query within a Vue component, call `useLocationsInRadiusQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsInRadiusQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLocationsInRadiusQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useLocationsInRadiusQuery(variables: LocationsInRadiusQueryVariables | VueCompositionApi.Ref<LocationsInRadiusQueryVariables> | ReactiveFunction<LocationsInRadiusQueryVariables>, options: VueApolloComposable.UseQueryOptions<LocationsInRadiusQuery, LocationsInRadiusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LocationsInRadiusQuery, LocationsInRadiusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LocationsInRadiusQuery, LocationsInRadiusQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<LocationsInRadiusQuery, LocationsInRadiusQueryVariables>(LocationsInRadiusDocument, variables, options);
}
export function useLocationsInRadiusLazyQuery(variables?: LocationsInRadiusQueryVariables | VueCompositionApi.Ref<LocationsInRadiusQueryVariables> | ReactiveFunction<LocationsInRadiusQueryVariables>, options: VueApolloComposable.UseQueryOptions<LocationsInRadiusQuery, LocationsInRadiusQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LocationsInRadiusQuery, LocationsInRadiusQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LocationsInRadiusQuery, LocationsInRadiusQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<LocationsInRadiusQuery, LocationsInRadiusQueryVariables>(LocationsInRadiusDocument, variables, options);
}
export type LocationsInRadiusQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<LocationsInRadiusQuery, LocationsInRadiusQueryVariables>;
export const RandomRecruiterDocument = gql`
    query randomRecruiter {
  randomRecruiter {
    id
    active_image
    email
    phone
    role
    title
  }
}
    `;

/**
 * __useRandomRecruiterQuery__
 *
 * To run a query within a Vue component, call `useRandomRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRandomRecruiterQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useRandomRecruiterQuery();
 */
export function useRandomRecruiterQuery(options: VueApolloComposable.UseQueryOptions<RandomRecruiterQuery, RandomRecruiterQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<RandomRecruiterQuery, RandomRecruiterQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<RandomRecruiterQuery, RandomRecruiterQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<RandomRecruiterQuery, RandomRecruiterQueryVariables>(RandomRecruiterDocument, {}, options);
}
export function useRandomRecruiterLazyQuery(options: VueApolloComposable.UseQueryOptions<RandomRecruiterQuery, RandomRecruiterQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<RandomRecruiterQuery, RandomRecruiterQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<RandomRecruiterQuery, RandomRecruiterQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<RandomRecruiterQuery, RandomRecruiterQueryVariables>(RandomRecruiterDocument, {}, options);
}
export type RandomRecruiterQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<RandomRecruiterQuery, RandomRecruiterQueryVariables>;
export const RecruiterDocument = gql`
    query recruiter($id: ID) {
  recruiter(id: $id) {
    id
    active_image
    title
    email
    phone
    whatsapp
    role
  }
}
    `;

/**
 * __useRecruiterQuery__
 *
 * To run a query within a Vue component, call `useRecruiterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecruiterQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useRecruiterQuery({
 *   id: // value for 'id'
 * });
 */
export function useRecruiterQuery(variables: RecruiterQueryVariables | VueCompositionApi.Ref<RecruiterQueryVariables> | ReactiveFunction<RecruiterQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<RecruiterQuery, RecruiterQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<RecruiterQuery, RecruiterQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<RecruiterQuery, RecruiterQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<RecruiterQuery, RecruiterQueryVariables>(RecruiterDocument, variables, options);
}
export function useRecruiterLazyQuery(variables: RecruiterQueryVariables | VueCompositionApi.Ref<RecruiterQueryVariables> | ReactiveFunction<RecruiterQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<RecruiterQuery, RecruiterQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<RecruiterQuery, RecruiterQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<RecruiterQuery, RecruiterQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<RecruiterQuery, RecruiterQueryVariables>(RecruiterDocument, variables, options);
}
export type RecruiterQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<RecruiterQuery, RecruiterQueryVariables>;
export const ReferenceDocument = gql`
    query reference($id: ID!) {
  reference(id: $id) {
    id
    active_image
    title
    text
    video
    profession {
      title
    }
    client {
      slug
      title
      alias
      active_image
      logo
    }
    vacancy {
      id
      title
      description
      company_description
      requirements
      conditions
      location {
        title
      }
      hours_per_week
      salary
      salary_max
      salary_unit
      recruiter {
        id
        active_image
        title
        email
        phone
        role
      }
      commissioner {
        references {
          text
          profession {
            title
          }
        }
      }
      sector {
        title
        kleur
        secundaire_kleur
      }
    }
    text
  }
}
    `;

/**
 * __useReferenceQuery__
 *
 * To run a query within a Vue component, call `useReferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReferenceQuery({
 *   id: // value for 'id'
 * });
 */
export function useReferenceQuery(variables: ReferenceQueryVariables | VueCompositionApi.Ref<ReferenceQueryVariables> | ReactiveFunction<ReferenceQueryVariables>, options: VueApolloComposable.UseQueryOptions<ReferenceQuery, ReferenceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReferenceQuery, ReferenceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReferenceQuery, ReferenceQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ReferenceQuery, ReferenceQueryVariables>(ReferenceDocument, variables, options);
}
export function useReferenceLazyQuery(variables?: ReferenceQueryVariables | VueCompositionApi.Ref<ReferenceQueryVariables> | ReactiveFunction<ReferenceQueryVariables>, options: VueApolloComposable.UseQueryOptions<ReferenceQuery, ReferenceQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReferenceQuery, ReferenceQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReferenceQuery, ReferenceQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ReferenceQuery, ReferenceQueryVariables>(ReferenceDocument, variables, options);
}
export type ReferenceQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ReferenceQuery, ReferenceQueryVariables>;
export const ReferencesDocument = gql`
    query references($per_page: Int) {
  references(per_page: $per_page) {
    data {
      id
      active_image
      title
      text
      video
      profession {
        title
      }
      client {
        published
        slug
        title
        alias
        active_image
        logo
      }
      vacancy {
        id
      }
      text
    }
    total
  }
}
    `;

/**
 * __useReferencesQuery__
 *
 * To run a query within a Vue component, call `useReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferencesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useReferencesQuery({
 *   per_page: // value for 'per_page'
 * });
 */
export function useReferencesQuery(variables: ReferencesQueryVariables | VueCompositionApi.Ref<ReferencesQueryVariables> | ReactiveFunction<ReferencesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ReferencesQuery, ReferencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReferencesQuery, ReferencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReferencesQuery, ReferencesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ReferencesQuery, ReferencesQueryVariables>(ReferencesDocument, variables, options);
}
export function useReferencesLazyQuery(variables: ReferencesQueryVariables | VueCompositionApi.Ref<ReferencesQueryVariables> | ReactiveFunction<ReferencesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ReferencesQuery, ReferencesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ReferencesQuery, ReferencesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ReferencesQuery, ReferencesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ReferencesQuery, ReferencesQueryVariables>(ReferencesDocument, variables, options);
}
export type ReferencesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ReferencesQuery, ReferencesQueryVariables>;
export const SectorDocument = gql`
    query sector {
  sector {
    id
    active_image
    title
    text
    slug
    color: kleur
    professions {
      label: title
      value: slug
    }
  }
}
    `;

/**
 * __useSectorQuery__
 *
 * To run a query within a Vue component, call `useSectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectorQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSectorQuery();
 */
export function useSectorQuery(options: VueApolloComposable.UseQueryOptions<SectorQuery, SectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SectorQuery, SectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SectorQuery, SectorQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SectorQuery, SectorQueryVariables>(SectorDocument, {}, options);
}
export function useSectorLazyQuery(options: VueApolloComposable.UseQueryOptions<SectorQuery, SectorQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SectorQuery, SectorQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SectorQuery, SectorQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SectorQuery, SectorQueryVariables>(SectorDocument, {}, options);
}
export type SectorQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SectorQuery, SectorQueryVariables>;
export const SectorFilterDocument = gql`
    query sectorFilter @cache(ttl: 30, public: true) {
  sector {
    value: title
    label: title
  }
}
    `;

/**
 * __useSectorFilterQuery__
 *
 * To run a query within a Vue component, call `useSectorFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectorFilterQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSectorFilterQuery();
 */
export function useSectorFilterQuery(options: VueApolloComposable.UseQueryOptions<SectorFilterQuery, SectorFilterQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SectorFilterQuery, SectorFilterQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SectorFilterQuery, SectorFilterQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SectorFilterQuery, SectorFilterQueryVariables>(SectorFilterDocument, {}, options);
}
export function useSectorFilterLazyQuery(options: VueApolloComposable.UseQueryOptions<SectorFilterQuery, SectorFilterQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SectorFilterQuery, SectorFilterQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SectorFilterQuery, SectorFilterQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SectorFilterQuery, SectorFilterQueryVariables>(SectorFilterDocument, {}, options);
}
export type SectorFilterQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SectorFilterQuery, SectorFilterQueryVariables>;
export const PageDocument = gql`
    query page($segments: String!) @cache(ttl: 60, public: true) {
  page(segments: $segments) {
    title
    slug
    active_image
    square: active_image
    images
    template_name
    template {
      ... on TemplateUitgebreid {
        text
        textWideBefore: text_wide_before
        textWide: text_wide
        text_sidebar
        search
        sidebar_dark
        reference {
          title
          text
          profession {
            title
          }
        }
        carousel
        show_branches
        header_color_1
        header_color_2
        header_image
        header_subtitle
        header_title
        youtube_id
      }
    }
    template {
      ... on TemplateStandaard {
        text
      }
    }
    template {
      ... on TemplateHome {
        text
        about
        about_image
        references: referenties {
          id
          active_image
          title
          text
          video
          profession {
            title
          }
          client {
            published
            slug
            alias
            title
            active_image
            logo
          }
        }
      }
    }
    children {
      title
      url
      children {
        title
        url
      }
    }
    parent {
      title
      url
      children {
        title
        url
        children {
          title
          url
        }
      }
    }
    seo {
      meta_title
      meta_description
      meta_index
      meta_follow
    }
  }
}
    `;

/**
 * __usePageQuery__
 *
 * To run a query within a Vue component, call `usePageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePageQuery({
 *   segments: // value for 'segments'
 * });
 */
export function usePageQuery(variables: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export function usePageLazyQuery(variables?: PageQueryVariables | VueCompositionApi.Ref<PageQueryVariables> | ReactiveFunction<PageQueryVariables>, options: VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PageQuery, PageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, variables, options);
}
export type PageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PageQuery, PageQueryVariables>;
export const SollicitatieprocessenDocument = gql`
    query sollicitatieprocessen($type: String) @cache(ttl: 60, public: true) {
  sollicitatieprocessen(type: $type) {
    title
    slug
    omschrijving
    active_image
  }
}
    `;

/**
 * __useSollicitatieprocessenQuery__
 *
 * To run a query within a Vue component, call `useSollicitatieprocessenQuery` and pass it any options that fit your needs.
 * When your component renders, `useSollicitatieprocessenQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSollicitatieprocessenQuery({
 *   type: // value for 'type'
 * });
 */
export function useSollicitatieprocessenQuery(variables: SollicitatieprocessenQueryVariables | VueCompositionApi.Ref<SollicitatieprocessenQueryVariables> | ReactiveFunction<SollicitatieprocessenQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SollicitatieprocessenQuery, SollicitatieprocessenQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SollicitatieprocessenQuery, SollicitatieprocessenQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SollicitatieprocessenQuery, SollicitatieprocessenQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SollicitatieprocessenQuery, SollicitatieprocessenQueryVariables>(SollicitatieprocessenDocument, variables, options);
}
export function useSollicitatieprocessenLazyQuery(variables: SollicitatieprocessenQueryVariables | VueCompositionApi.Ref<SollicitatieprocessenQueryVariables> | ReactiveFunction<SollicitatieprocessenQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SollicitatieprocessenQuery, SollicitatieprocessenQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SollicitatieprocessenQuery, SollicitatieprocessenQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SollicitatieprocessenQuery, SollicitatieprocessenQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SollicitatieprocessenQuery, SollicitatieprocessenQueryVariables>(SollicitatieprocessenDocument, variables, options);
}
export type SollicitatieprocessenQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SollicitatieprocessenQuery, SollicitatieprocessenQueryVariables>;
export const SubjectDocument = gql`
    query subject($q: String, $subjects: [String], $forEmployers: Boolean) {
  subject(q: $q, subjects: $subjects, forEmployers: $forEmployers) {
    id
    active_image
    name
    text
    questions {
      id
      title
      text
    }
  }
}
    `;

/**
 * __useSubjectQuery__
 *
 * To run a query within a Vue component, call `useSubjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSubjectQuery({
 *   q: // value for 'q'
 *   subjects: // value for 'subjects'
 *   forEmployers: // value for 'forEmployers'
 * });
 */
export function useSubjectQuery(variables: SubjectQueryVariables | VueCompositionApi.Ref<SubjectQueryVariables> | ReactiveFunction<SubjectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SubjectQuery, SubjectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SubjectQuery, SubjectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SubjectQuery, SubjectQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SubjectQuery, SubjectQueryVariables>(SubjectDocument, variables, options);
}
export function useSubjectLazyQuery(variables: SubjectQueryVariables | VueCompositionApi.Ref<SubjectQueryVariables> | ReactiveFunction<SubjectQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SubjectQuery, SubjectQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SubjectQuery, SubjectQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SubjectQuery, SubjectQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SubjectQuery, SubjectQueryVariables>(SubjectDocument, variables, options);
}
export type SubjectQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SubjectQuery, SubjectQueryVariables>;
export const SubjectFilterDocument = gql`
    query subjectFilter($forEmployers: Boolean) {
  subject(forEmployers: $forEmployers) {
    value: name
    label: name
  }
}
    `;

/**
 * __useSubjectFilterQuery__
 *
 * To run a query within a Vue component, call `useSubjectFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectFilterQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSubjectFilterQuery({
 *   forEmployers: // value for 'forEmployers'
 * });
 */
export function useSubjectFilterQuery(variables: SubjectFilterQueryVariables | VueCompositionApi.Ref<SubjectFilterQueryVariables> | ReactiveFunction<SubjectFilterQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SubjectFilterQuery, SubjectFilterQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SubjectFilterQuery, SubjectFilterQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SubjectFilterQuery, SubjectFilterQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SubjectFilterQuery, SubjectFilterQueryVariables>(SubjectFilterDocument, variables, options);
}
export function useSubjectFilterLazyQuery(variables: SubjectFilterQueryVariables | VueCompositionApi.Ref<SubjectFilterQueryVariables> | ReactiveFunction<SubjectFilterQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SubjectFilterQuery, SubjectFilterQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SubjectFilterQuery, SubjectFilterQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SubjectFilterQuery, SubjectFilterQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SubjectFilterQuery, SubjectFilterQueryVariables>(SubjectFilterDocument, variables, options);
}
export type SubjectFilterQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SubjectFilterQuery, SubjectFilterQueryVariables>;
export const VacancyDocument = gql`
    query vacancy($slug: String) {
  vacancy(slug: $slug) {
    id
    title
    slug
    description
    company_description
    requirements
    conditions
    procedure
    city
    client
    location {
      title
    }
    hours_per_week
    salary
    salary_max
    salary_unit
    salary_per_hour
    ms_job_number
    recruiter {
      id
      active_image
      title
      email
      phone
      whatsapp
      role
      department {
        id
        title
      }
    }
    commissioner {
      id
      published
      slug
      references {
        text
        profession {
          title
        }
      }
    }
    sector {
      title
      slug
      kleur
      secundaire_kleur
    }
  }
}
    `;

/**
 * __useVacancyQuery__
 *
 * To run a query within a Vue component, call `useVacancyQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacancyQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVacancyQuery({
 *   slug: // value for 'slug'
 * });
 */
export function useVacancyQuery(variables: VacancyQueryVariables | VueCompositionApi.Ref<VacancyQueryVariables> | ReactiveFunction<VacancyQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<VacancyQuery, VacancyQueryVariables>(VacancyDocument, variables, options);
}
export function useVacancyLazyQuery(variables: VacancyQueryVariables | VueCompositionApi.Ref<VacancyQueryVariables> | ReactiveFunction<VacancyQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacancyQuery, VacancyQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<VacancyQuery, VacancyQueryVariables>(VacancyDocument, variables, options);
}
export type VacancyQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<VacancyQuery, VacancyQueryVariables>;
export const VacancyLandingPageDocument = gql`
    query vacancyLandingPage($slug: String!, $filters: VacancyFilters, $order: String) {
  vacancyLandingPage(slug: $slug, filters: $filters, order: $order) {
    page {
      active_image
      title
      intro
      renderable_intro
      renderable_text
      text
      type
      meta_title
      meta_description
      kleur
      secundaire_kleur
    }
    sector {
      title
      active_image
      kleur
      secundaire_kleur
    }
    professions {
      title
      active_image
      slug
    }
    vacancies {
      id
      slug
      title
      city
      location {
        published
      }
      hours_per_week
      salary
      salary_max
      salary_unit
      salary_per_hour
      requirements
      excerpt
      usps: usp_s
      commissioner {
        slug
        id
        published
      }
    }
    recruiter {
      id
      title
      role
      active_image
      email
      phone
    }
  }
}
    `;

/**
 * __useVacancyLandingPageQuery__
 *
 * To run a query within a Vue component, call `useVacancyLandingPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacancyLandingPageQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVacancyLandingPageQuery({
 *   slug: // value for 'slug'
 *   filters: // value for 'filters'
 *   order: // value for 'order'
 * });
 */
export function useVacancyLandingPageQuery(variables: VacancyLandingPageQueryVariables | VueCompositionApi.Ref<VacancyLandingPageQueryVariables> | ReactiveFunction<VacancyLandingPageQueryVariables>, options: VueApolloComposable.UseQueryOptions<VacancyLandingPageQuery, VacancyLandingPageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacancyLandingPageQuery, VacancyLandingPageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacancyLandingPageQuery, VacancyLandingPageQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<VacancyLandingPageQuery, VacancyLandingPageQueryVariables>(VacancyLandingPageDocument, variables, options);
}
export function useVacancyLandingPageLazyQuery(variables?: VacancyLandingPageQueryVariables | VueCompositionApi.Ref<VacancyLandingPageQueryVariables> | ReactiveFunction<VacancyLandingPageQueryVariables>, options: VueApolloComposable.UseQueryOptions<VacancyLandingPageQuery, VacancyLandingPageQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacancyLandingPageQuery, VacancyLandingPageQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacancyLandingPageQuery, VacancyLandingPageQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<VacancyLandingPageQuery, VacancyLandingPageQueryVariables>(VacancyLandingPageDocument, variables, options);
}
export type VacancyLandingPageQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<VacancyLandingPageQuery, VacancyLandingPageQueryVariables>;
export const VacancySearchDocument = gql`
    query vacancySearch($filters: VacancyFilters, $order: String, $order_direction: String, $per_page: Int, $page: Int) {
  vacancySearch(
    filters: $filters
    order: $order
    order_direction: $order_direction
    per_page: $per_page
    page: $page
  ) {
    data {
      id
      slug
      title
      city
      location {
        published
      }
      hours_per_week
      salary
      salary_max
      salary_unit
      salary_per_hour
      requirements
      excerpt
      usps: usp_s
      recruiter {
        id
        title
        role
        active_image
        email
        phone
      }
      commissioner {
        id
        published
        slug
        references {
          image: active_image
        }
      }
    }
    last_page
    total
  }
}
    `;

/**
 * __useVacancySearchQuery__
 *
 * To run a query within a Vue component, call `useVacancySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacancySearchQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVacancySearchQuery({
 *   filters: // value for 'filters'
 *   order: // value for 'order'
 *   order_direction: // value for 'order_direction'
 *   per_page: // value for 'per_page'
 *   page: // value for 'page'
 * });
 */
export function useVacancySearchQuery(variables: VacancySearchQueryVariables | VueCompositionApi.Ref<VacancySearchQueryVariables> | ReactiveFunction<VacancySearchQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<VacancySearchQuery, VacancySearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacancySearchQuery, VacancySearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacancySearchQuery, VacancySearchQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<VacancySearchQuery, VacancySearchQueryVariables>(VacancySearchDocument, variables, options);
}
export function useVacancySearchLazyQuery(variables: VacancySearchQueryVariables | VueCompositionApi.Ref<VacancySearchQueryVariables> | ReactiveFunction<VacancySearchQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<VacancySearchQuery, VacancySearchQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacancySearchQuery, VacancySearchQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacancySearchQuery, VacancySearchQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<VacancySearchQuery, VacancySearchQueryVariables>(VacancySearchDocument, variables, options);
}
export type VacancySearchQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<VacancySearchQuery, VacancySearchQueryVariables>;
export const VacancySimilarDocument = gql`
    query vacancySimilar($filters: VacancyFilters, $per_page: Int, $vacancy: ID!) {
  vacancySimilar(filters: $filters, per_page: $per_page, vacancy: $vacancy) {
    id
    slug
    title
    city
    hours_per_week
    salary
    salary_max
    salary_unit
    salary_per_hour
    commissioner {
      id
      slug
      published
    }
    requirements
    recruiter {
      id
      title
      role
      active_image
      email
      phone
    }
  }
}
    `;

/**
 * __useVacancySimilarQuery__
 *
 * To run a query within a Vue component, call `useVacancySimilarQuery` and pass it any options that fit your needs.
 * When your component renders, `useVacancySimilarQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useVacancySimilarQuery({
 *   filters: // value for 'filters'
 *   per_page: // value for 'per_page'
 *   vacancy: // value for 'vacancy'
 * });
 */
export function useVacancySimilarQuery(variables: VacancySimilarQueryVariables | VueCompositionApi.Ref<VacancySimilarQueryVariables> | ReactiveFunction<VacancySimilarQueryVariables>, options: VueApolloComposable.UseQueryOptions<VacancySimilarQuery, VacancySimilarQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacancySimilarQuery, VacancySimilarQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacancySimilarQuery, VacancySimilarQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<VacancySimilarQuery, VacancySimilarQueryVariables>(VacancySimilarDocument, variables, options);
}
export function useVacancySimilarLazyQuery(variables?: VacancySimilarQueryVariables | VueCompositionApi.Ref<VacancySimilarQueryVariables> | ReactiveFunction<VacancySimilarQueryVariables>, options: VueApolloComposable.UseQueryOptions<VacancySimilarQuery, VacancySimilarQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<VacancySimilarQuery, VacancySimilarQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<VacancySimilarQuery, VacancySimilarQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<VacancySimilarQuery, VacancySimilarQueryVariables>(VacancySimilarDocument, variables, options);
}
export type VacancySimilarQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<VacancySimilarQuery, VacancySimilarQueryVariables>;